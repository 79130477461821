<template>
  <ui-row
    large
    spaceEvenly
    stackMobile
    alignTop
    padding
  >

    <ui-col
      flexStart
      small
      mobile
    >
     <div class="account-menu">
        <ui-h small color="black">My Account</ui-h>
        <ul>
            <li>{{user.email}}</li>
            <li><router-link to="/account/details">Account Details</router-link></li>
            <li><router-link to="/account/subscription">Subscription</router-link></li>
            <li><router-link to="/account/help">Help and Information</router-link></li>
            <li><router-link to="/account/logout">Logout</router-link></li>
        </ul>
        <div
            v-if="invoices.length > 0 && !loadingInvoices"
        >
            <ui-h small color="black">Payments History</ui-h>
            <ul>
                <li
                    v-for="(invoice, index) in invoices"
                    :key="index"
                >
                    <a 
                        :href="invoice.stripeUrl"
                        target="_blank"
                    >
                        {{moment.unix(invoice.start).format('dddd MMMM Do YYYY')}}
                    </a>
                </li>
            </ul>
        </div>
     </div>
    </ui-col>

    <ui-col
      flexStart
      large
      mobile
    >
       <vue-page-transition name="fade-in-right">>
          <router-view />
       </vue-page-transition>
    </ui-col>
  </ui-row>
</template>

<script>
import firebase from '@firebase/app';
export default {
  computed: {
		user() {
			return this.$store.getters.getterUser
		},

        loadingSub() {
            return this.$store.getters.getterIsLoadingUserSub
        },

        invoices() {
            return this.$store.getters.getterInvoices
        },

        loadingInvoices() {
            return this.$store.getters.getterIsLoadingInvoices
        }
  },

  methods: {
        async getCustomClaimRole() {
            await firebase.auth().currentUser.getIdToken(true);
            const decodedToken = await firebase.auth().currentUser.getIdTokenResult();
            return decodedToken.claims.stripeRole;
        },

        async getInvoices(collectionid) {
            console.log(collectionid)
            this.$store.commit('mutationLoadingInvoices', true)
            this.$store.dispatch('actionGetMonthlyInvoices', {
                uid: this.user.uid,
                subscription: collectionid
            })
            .then((invoices) => {
                this.$store.commit('mutationStoreInvoices', invoices)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                this.$store.commit('mutationLoadingInvoices', false)
            })
        }
  },

  mounted() {
    this.getCustomClaimRole()
      .then((res) => {
            // if no metadata in stripe has been set for product
            // or no sub has been purchase res returns undefined

            if(res != undefined) {
                // check to see if the subscription is still valid
                this.$store.commit('mutationLoadingSub', true)
                this.$store.dispatch('actionGetUserSubscription', {
                    uid: this.user.uid,
                    product: process.env.VUE_APP_STRIPE_MONTH_SUB_PRICE_KEY
                })
                .then((subs) => {
                    subs.length 
                        ? this.$store.commit('mutationFilterSubscriptions', subs)
                        : null
                    
                })
                .catch((err) => {
                    console.log(err)
                    this.$toasted.show(err, {
                        position: 'bottom-center',
                        theme: 'bubble'
                    })
                })
                .finally(() => {
                    this.$store.commit('mutationLoadingSub', false)
                })
            }
      })
      .catch((err) => {
          console.log(err)
      })
  }
}
</script>

<style lang="scss" scoped>

  .account-menu {
    border-right: 1px solid $primary;
  }

    ul {
        list-style: none;
        padding: 0;
        li {

            a {
              text-decoration: none;
              font-size: rem(18);

              &:hover {
                color: $secondary;
              }
            }

            a.router-link-exact-active, a.router-link-active {
                text-decoration: none;
                color: $secondary;

                &:hover {
                    color: $secondary;
                }
            }

            & + li {
                margin-top: rem(20)
            }
        }
    }
</style>